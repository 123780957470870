import * as React from 'react';

function TrayIcon(props) {
  return (
    <svg fill="#d69935" viewBox="0 0 512 512" {...props}>
      <path d="M107.672 315.408A8 8 0 10121 324.256a150.191 150.191 0 01125.336-67.4 8 8 0 000-16c-55.809-.014-107.897 27.991-138.664 74.552z" />
      <path d="M504 416.856h-48v-9.664a188.743 188.743 0 00-58.448-137.232 8 8 0 10-11.089 11.528 172.911 172.911 0 0153.536 125.704v9.664H72v-9.664c.106-96.239 78.097-174.23 174.336-174.336h19.328a175.119 175.119 0 0144.048 5.6 8 8 0 009.958-5.37 8 8 0 00-5.959-10.11 190.25 190.25 0 00-33.712-5.528v-24.592a8 8 0 00-8-8h-32a8 8 0 00-8 8v24.592c-99.207 7.598-175.869 90.246-176 189.744v9.664H8a8 8 0 000 16h496a8 8 0 000-16zm-256-216h16v16h-16v-16z" />
      <path d="M338.672 178.8C346.12 185.448 352 190.696 352 201.408s-5.88 15.96-13.328 22.648A43.759 43.759 0 00320 258.568a8 8 0 0016 0c0-10.712 5.88-15.96 13.328-22.608A43.793 43.793 0 00368 201.408a43.776 43.776 0 00-18.672-34.544c-7.448-6.648-13.328-11.896-13.328-22.6s5.88-15.944 13.328-22.592A43.738 43.738 0 00368 87.144a8 8 0 00-16 0c0 10.696-5.88 15.936-13.328 22.584A43.743 43.743 0 00320 144.264a43.767 43.767 0 0018.672 34.536zm48 0C394.12 185.448 400 190.696 400 201.408s-5.88 16-13.328 22.616A43.758 43.758 0 00368 258.568a8 8 0 0016 0c0-10.712 5.88-15.96 13.328-22.608A43.793 43.793 0 00416 201.408a43.776 43.776 0 00-18.672-34.544c-7.448-6.648-13.328-11.896-13.328-22.6s5.88-15.944 13.328-22.592A43.738 43.738 0 00416 87.144a8 8 0 00-16 0c0 10.696-5.88 15.936-13.328 22.584A43.743 43.743 0 00368 144.264a43.767 43.767 0 0018.672 34.536zm48 0C442.12 185.448 448 190.696 448 201.408s-5.88 16-13.328 22.616A43.758 43.758 0 00416 258.568a8 8 0 0016 0c0-10.712 5.88-15.96 13.328-22.608A43.793 43.793 0 00464 201.408a43.776 43.776 0 00-18.672-34.544c-7.448-6.648-13.328-11.896-13.328-22.6s5.88-15.944 13.328-22.592A43.738 43.738 0 00464 87.144a8 8 0 00-16 0c0 10.696-5.88 15.936-13.328 22.584A43.743 43.743 0 00416 144.264a43.767 43.767 0 0018.672 34.536z" />
    </svg>
  );
}

export { TrayIcon };
