import * as React from 'react';

function WineIcon(props) {
  return (
    <svg
      width={640}
      height={640}
      fill="#d69935"
      viewBox="-48 0 480 480"
      {...props}
    >
      <path d="M132.746 147.465A151.227 151.227 0 01120 86.527V48a8 8 0 008-8V8a8 8 0 00-8-8H56a8 8 0 00-8 8v32a8 8 0 008 8v38.527a151.289 151.289 0 01-12.742 60.938L20.8 198.809A246.416 246.416 0 000 298.199V472a8 8 0 008 8h160a8 8 0 008-8V298.2a246.35 246.35 0 00-20.8-99.391zM64 16h48v16H64zm96 448H16V298.2a230.59 230.59 0 0119.457-92.993l22.457-51.344A167.166 167.166 0 0072 86.527V48h32v38.527a167.105 167.105 0 0014.09 67.336l22.453 51.344A230.59 230.59 0 01160 298.199zm0 0" />
      <path d="M104.566 202.832l6.657 15.2A202.65 202.65 0 01120 242.245a8.003 8.003 0 009.93 5.434c4.242-1.239 6.672-5.688 5.43-9.926a219.303 219.303 0 00-9.512-26.129l-6.657-15.2a7.99 7.99 0 00-6.449-4.968 8.01 8.01 0 00-7.465 3.258 8.01 8.01 0 00-.742 8.11zM94.25 179.199a7.996 7.996 0 007.336 4.801 7.848 7.848 0 003.2-.672c4.042-1.773 5.886-6.484 4.116-10.527l-5.476-12.543a8.003 8.003 0 00-10.434-3.906 7.995 7.995 0 00-4.222 10.304zM40 280a8 8 0 00-8 8v136a8 8 0 008 8h96a8 8 0 008-8V288a8 8 0 00-8-8zm88 136H48V296h80zm230.398 44.8A104.688 104.688 0 00304 440.32V327.594c45.277-4.172 79.941-42.125 80-87.594 0-.379-.023-.762-.078-1.137l-4.211-29.488v-.055l-11.79-82.457A8.004 8.004 0 00360 120H232a7.997 7.997 0 00-7.918 6.863l-16 112A7.907 7.907 0 00208 240c.059 45.469 34.723 83.422 80 87.594v112.734a104.654 104.654 0 00-54.398 20.48l-6.403 4.801c-2.746 2.067-3.867 5.66-2.777 8.922s4.14 5.465 7.578 5.469h128a8 8 0 004.8-14.398zM238.938 136h114.125l9.601 67.2-15.398 1.722a135.202 135.202 0 01-52.137-4.348l-.953-.277a151.673 151.673 0 00-60.512-4.563l-3.32.41zM224 240.55l4-28 7.633-.933a135.28 135.28 0 0154.039 4l.937.278a150.942 150.942 0 0058.438 4.898l15.883-1.777L368 240.55c0 39.765-32.234 72-72 72s-72-32.235-72-72zM259.39 464a89.488 89.488 0 0173.22 0zm0 0" />
    </svg>
  );
}

export { WineIcon };
