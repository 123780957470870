import * as React from 'react';

function CandleIcon(props) {
  return (
    <svg
      fill="#d69935"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      clipRule="evenodd"
      viewBox="0 0 512 496"
      {...props}
    >
      <path d="M32.224 463.968c-4.424 0-8.04-3.576-8.04-8 0-4.424 3.536-8 7.952-8h.088c4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8zm455.792-84.28h-.002c-4.388 0-8-3.611-8-8a8.008 8.008 0 013.986-6.92 24.107 24.107 0 0012-20.8c0-13.232-10.768-24-24-24-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8 22.056 0 40 17.944 40 40a40.179 40.179 0 01-19.984 34.64 7.92 7.92 0 01-4 1.08z" />
      <path d="M272.008 495.968c-4.389 0-8-3.611-8-8a8.01 8.01 0 014.2-7.04l216-116.296a8 8 0 013.948-1.042c4.389 0 8 3.611 8 8a8.01 8.01 0 01-4.372 7.13l-216 116.296a7.916 7.916 0 01-3.776.952z" />
      <path d="M272.008 495.968c-.36 0-.72-.024-1.088-.072l-175.992-24c-3.947-.538-6.921-3.945-6.921-7.928 0-4.389 3.612-8.001 8.001-8.001.361 0 .722.024 1.08.073l175.992 24c3.95.534 6.929 3.942 6.929 7.928 0 4.389-3.611 8-8 8h-.001z" />
      <path d="M96.008 479.952H72.064c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8h15.944V279.968H72.064c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8h23.944c4.389 0 8 3.611 8 8v199.984c0 4.389-3.611 8-8 8zm215.984-135.984h-.009c-4.388 0-8-3.611-8-8 0-3.944 2.917-7.329 6.817-7.912l154.536-23.528c.436-.073.878-.11 1.321-.11 4.388 0 8 3.612 8 8 0 3.98-2.97 7.385-6.913 7.926L313.2 343.872c-.4.064-.803.096-1.208.096z" />
      <path d="M280 375.968c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8 13.232 0 24-10.768 24-24 .036-11.625-8.407-21.677-19.864-23.648-3.925-.558-6.873-3.955-6.873-7.92 0-4.389 3.611-8 8-8 .535 0 1.069.053 1.593.16 19.108 3.268 33.2 20.023 33.144 39.408 0 22.056-17.944 40-40 40z" />
      <path d="M285.504 312.44a7.693 7.693 0 01-1.536-.152l-125.504-24.472a8.024 8.024 0 01-6.468-7.852c0-4.389 3.611-8 8-8 .514 0 1.027.05 1.532.148l125.504 24.472a8.024 8.024 0 016.489 7.856c0 4.389-3.611 8-8 8h-.017z" />
      <path d="M160 287.968H96.008c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8H160c4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8zm116.64 87.816l-.624-.024-100.632-7.816c-4.17-.289-7.447-3.801-7.447-7.981 0-4.389 3.612-8 8-8 .227 0 .453.01.679.029l100.632 7.816c4.143.322 7.381 3.82 7.381 7.976 0 4.384-3.605 7.994-7.989 8zm189.88-55.36c-4.38-.012-7.978-3.62-7.978-8 0-3.887 2.833-7.24 6.666-7.888a40.998 40.998 0 016.792-.56c4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8-1.391 0-2.78.115-4.152.344-.439.07-.883.105-1.328.104z" />
      <path d="M280 375.968c-1.44 0-2.856-.08-4.248-.24-4.356-.494-7.536-4.483-7.048-8.84.411-4.017 3.833-7.108 7.871-7.108.324 0 .648.02.969.06.816.088 1.624.136 2.456.136.084-.003.169-.004.253-.004 4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8-.084 0-.169-.001-.253-.004zm-207.992 120H8c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8h56.008v-216H8c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8h64.008c4.389 0 8 3.611 8 8v232c0 4.389-3.611 8-8 8zm175.976-303.936c-13.232 0-24-10.768-24-24s10.768-24 24-24c4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8-4.389 0-8 3.611-8 8 0 4.389 3.611 8 8 8 4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8zm79.968 0c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8 4.389 0 8-3.611 8-8 0-4.389-3.611-8-8-8-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8 13.232 0 24 10.768 24 24s-10.768 24-24 24z" />
      <path d="M327.952 160.032h-79.968c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8h79.968c4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8zm0 32h-79.968c-4.389 0-8-3.611-8-8 0-4.389 3.611-8 8-8h79.968c4.389 0 8 3.611 8 8 0 4.389-3.611 8-8 8z" />
      <path d="M343.952 339.112c-4.389 0-8-3.611-8-8v-163.08c0-4.389 3.611-8 8-8 4.389 0 8 3.611 8 8v163.08c0 4.389-3.611 8-8 8zM231.984 302c-4.389 0-8-3.611-8-8V168.032c0-4.389 3.611-8 8-8 4.389 0 8 3.611 8 8V294c0 4.389-3.611 8-8 8zM288 176.032c-4.389 0-8-3.611-8-8V88c0-4.389 3.611-8 8-8 4.389 0 8 3.611 8 8v80.032c0 4.389-3.611 8-8 8z" />
      <path d="M288 136.032c-26.472 0-48-21.528-48-48C240 66.808 257.552 0 288 0s48 66.808 48 88.032c0 26.464-21.528 48-48 48zM288 16c-13.744 0-32 49.424-32 72.032 0 17.648 14.352 32 32 32s32-14.352 32-32C320 65.424 301.744 16 288 16z" />
    </svg>
  );
}

export { CandleIcon };
