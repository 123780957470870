import * as React from 'react';

function AirportIcon(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      width="100%"
      height="100%"
      fill="#d69935"
      {...props}
    >
      <path d="M110.383 191.102L76.606 242.92a10.461 10.461 0 00-.432 10.718 10.468 10.468 0 009.208 5.479h.017l24.16-.042a12.043 12.043 0 007.175-2.406l49.541-37.29 70.007 11.998c.062.011.125.02.187.029 1.189.174 2.423.255 3.579.256l27.142-.042-75.72 128.213a10.22 10.22 0 00-.073 10.245 10.217 10.217 0 008.85 5.136h.01l42.937-.027a15.072 15.072 0 0010.534-4.277l38.12-36.994a7.49 7.49 0 00-10.431-10.747l-38.12 36.994a.166.166 0 01-.116.048l-34.555.022L284.6 231.592l91.332-.142-69.114 67.071a7.49 7.49 0 005.216 12.862c1.878 0 3.76-.703 5.214-2.115l80.222-77.851 37.985-.059a49.683 49.683 0 0018.754-3.695l36.88-15.04c9.191-3.749 14.891-12.245 14.874-22.173-.018-9.928-5.747-18.403-14.952-22.12l-36.931-14.912a49.658 49.658 0 00-18.677-3.629h-.091l-38.606.065L253.729 11.101a15.074 15.074 0 00-10.537-4.277l-42.932-.028h-.01a10.219 10.219 0 00-8.852 5.136 10.22 10.22 0 00.073 10.245L266.99 150.05l-27.086.042a24.781 24.781 0 00-3.763.3l-69.964 12.24-49.674-37.121a12.048 12.048 0 00-7.171-2.38h-.012l-24.16.042a10.468 10.468 0 00-9.203 5.51 10.464 10.464 0 00.469 10.716zm-2.04-52.991l49.385 36.903a13.517 13.517 0 0010.393 2.482l70.499-12.333a9.632 9.632 0 011.314-.095l100.039-.156a7.487 7.487 0 007.476-7.5 7.487 7.487 0 00-7.488-7.476h-.012l-55.582.087-75.739-128.246 34.552.023c.045 0 .088.017.119.048l132.359 128.448a7.485 7.485 0 002.43 14.565h.013l57.238-.097h.063c4.5 0 8.898.854 13.071 2.539l36.931 14.912c5.171 2.088 5.58 6.85 5.583 8.26.002 1.41-.391 6.174-5.554 8.28l-36.879 15.04a34.779 34.779 0 01-13.125 2.586l-195.411.305h-.021c-.426 0-.86-.03-1.293-.089l-70.537-12.089a13.512 13.512 0 00-10.383 2.517l-49.257 37.077-14.831.025 29.768-45.667a13.478 13.478 0 00-.026-14.76l-29.926-45.563z" />
      <circle
        cx={269.269}
        cy={190.266}
        r={9.63}
        transform="rotate(-5.73 269.985 189.901)"
      />
      <ellipse
        cx={304.905}
        cy={190.243}
        rx={9.63}
        ry={9.629}
        transform="rotate(-72.54 304.93 190.224)"
      />
      <ellipse
        cx={339.902}
        cy={190.221}
        rx={9.63}
        ry={9.629}
        transform="rotate(-45 339.892 190.298)"
      />
      <path d="M375.545 199.828c5.318-.003 9.627-4.317 9.624-9.636-.003-5.318-4.317-9.627-9.636-9.624-5.318.003-9.627 4.318-9.623 9.636s4.316 9.627 9.635 9.624zm118.983 217.453H146.55v-32.588h4.461c4.136 0 7.488-3.352 7.488-7.488s-3.352-7.488-7.488-7.488h-4.461v-32.588h4.461c4.136 0 7.488-3.352 7.488-7.488s-3.352-7.488-7.488-7.488h-4.461v-8.805a12.45 12.45 0 00-7.671-11.517l-36.598-15.279a22.6 22.6 0 00-17.309 0L48.371 301.83a12.448 12.448 0 00-7.671 11.517v8.805h-4.008c-4.136 0-7.488 3.352-7.488 7.488s3.352 7.488 7.488 7.488H40.7v32.588h-4.008c-4.136 0-7.488 3.352-7.488 7.488s3.352 7.488 7.488 7.488H40.7v32.588H17.472C7.838 417.281 0 425.119 0 434.753v52.978c0 9.634 7.838 17.472 17.472 17.472h24.836c4.136 0 7.488-3.352 7.488-7.488s-3.352-7.488-7.488-7.488H17.472a2.498 2.498 0 01-2.496-2.496v-52.978a2.498 2.498 0 012.496-2.496h477.055a2.498 2.498 0 012.496 2.496v52.978a2.498 2.498 0 01-2.496 2.496h-41.44v-28.899c0-6.882-5.599-12.48-12.48-12.48H250.172c-6.882 0-12.48 5.599-12.48 12.48v28.899H81.62c-4.136 0-7.488 3.352-7.488 7.488s3.352 7.488 7.488 7.488h412.907c9.634 0 17.472-7.838 17.472-17.472v-52.978c.001-9.634-7.837-17.472-17.471-17.472zm-362.955-47.564H55.677v-32.588h75.896zm-40.832-69.346a7.538 7.538 0 015.769 0l35.063 14.639v7.141H55.676v-7.141zm-35.065 84.322h75.897v32.588H55.676zm297.06 79.131h37.673v26.403h-37.673zm-14.976 26.403h-37.943v-26.403h37.943zm-85.092-26.403h32.173v26.403h-32.173zm152.717 26.403v-26.403h32.726v26.403z" />
    </svg>
  );
}

export { AirportIcon };
