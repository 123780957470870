import * as React from 'react';

function CarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 682.667 682.667"
      {...props}
    >
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path fill="#d69935" d="M0 512h512V0H0z" data-original="#000000" />
        </clipPath>
      </defs>
      <g
        fill="none"
        stroke="#d69935"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        clipPath="url(#a)"
        transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
      >
        <path
          d="M143.774 207.903c0-22.136-17.944-40.081-40.081-40.081-22.135 0-40.08 17.945-40.08 40.081 0 22.136 17.945 40.081 40.08 40.081 22.137 0 40.081-17.945 40.081-40.081zM143.774 191.871h232.468M109.362 213.571l-11.336-11.336M456.403 207.903c0-22.136-17.944-40.081-40.081-40.081-22.135 0-40.08 17.945-40.08 40.081 0 22.136 17.945 40.081 40.08 40.081 22.137 0 40.081-17.945 40.081-40.081zM410.654 213.571l11.336-11.336"
          data-original="#000000"
        />
        <path
          d="M63.613 199.887l-38.671 11.049A24.049 24.049 0 007.5 234.059v47.869c0 8.085 6.021 14.905 14.044 15.908l29.648 3.706a120.226 120.226 0 0138.859 11.766l38.043 19.021a112.222 112.222 0 0050.189 11.848h71.419c19.82 0 39.227-5.652 55.948-16.292l62.576-39.821s72.665 0 117.998-21.052c11.226-5.214 18.276-16.605 18.276-28.984v-26.612c0-12.409-7.178-19.545-16.032-19.545"
          data-original="#000000"
        />
        <path
          d="M159.806 288.065h-15.462a40.082 40.082 0 00-28.342 11.74l-12.309 12.308M368.226 288.065H191.872M175.839 223.935h168.338M7.5 264.016h16.032M480.452 247.984h19.372M223.935 288.065v56.113M131.75 291.321l10.436 46.098"
          data-original="#000000"
        />
      </g>
    </svg>
  );
}

export { CarIcon };
